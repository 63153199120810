<template>
  <b-nav-item-dropdown v-if="user" right>
    <template #button-content>
      <b-avatar
        size="md"
        variant="light-success"
        :text="user.name.substring(0,1).toUpperCase()+user.lastname.substring(0,1).toUpperCase()"
      />

    </template>

    <div class="pr-2 pl-2 pb-half pt-half w-user-custom">
      <div class="d-flex justify-content-between">
        <b-avatar
          size="lg"
          variant="light-success"
          class="mt-half"
          :text="user.name.substring(0,1).toUpperCase()+user.lastname.substring(0,1).toUpperCase()"
        />
        <div class="ml-1 items-web-color">
          <p class="font-weight-bolder mb-0">{{ fullNameUser  }}</p>
          <div class="">
            <span class="">{{ user.canalventa.name }}</span><br>
            <span> {{puntoDeVenta}} </span>
          </div>
        </div>
      </div>
    </div>


    <b-dropdown-divider />

    <b-dropdown-text>
      <span class="user-status items-web-color">
        <strong>Sección del Canal de venta</strong> <br>
        {{user.sectionCanal.name}}
      </span>
    </b-dropdown-text>

    <b-dropdown-divider />

    <div class="d-flex justify-content-around">
      <!-- phrase -->
      <div class="pr-1 pl-1 pt-half pb-half">
        <b-nav-item @click="showPhrase">
          <feather-icon
            size="21"
            icon="SmileIcon"
          />
        </b-nav-item>
      </div>

      <div class="pr-1 pl-1 pt-half pb-half">
        <dark-Toggler/>
      </div>
    </div>

    <b-dropdown-divider />

   <!-- <b-dropdown-item
      @click="clearAll"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="Trash2Icon"
        class="mr-50"
        style="color:red;"
      />
      <span>Limpiar todo</span>
    </b-dropdown-item> -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="_logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Salir</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import DarkToggler from '@/modules/layouts/components/app-navbar/components/DarkToggler'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  components: {
    DarkToggler
  },

  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),              
    }
  },
  computed:{
    ...mapState('shop',['puntoventa']),                     
    ...mapState('auth',['user','quote']),
    ...mapState('appConfig',['windowWidth']),    
    fullNameUser(){
      const stringName = this.user.name +' '+ this.user.lastname
      return this.windowWidth > 1300 ? stringName : stringName.substring(0, 12) + '...'        
    },
    puntoDeVenta(){
      if( this.puntoventa && this.user ){
        const puntoDeVenta = this.user.puntosventa.find( pv => pv.id === this.puntoventa )
        return puntoDeVenta.name 
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapMutations('auth',['logout',]),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom', 'setGlobalCurrency', 'setCurrency']),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapMutations('products',['setProducts','setSelectedCategory']),

    _logout() {
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.setProducts([])
      this.setSelectedCategory(null)
      this.setGlobalCurrency(null)
      this.setCurrency(null)
      // this.$router.push({ name: 'auth-login' })
      localStorage.removeItem('vuex')
      window.location.reload(true)
    },
    showPhrase(){
      const text = `🎉🎉 ${this.quote.phrase} `
			showAlertMessage( `Por: ${this.quote.author}`, 'BellIcon', text, 'success', 5000, 'bottom-right')
    },
    // clearAll(){
    //   this.logout()
    //   this.unsetCanalVenta()
    //   this.unsetPuntoDeVenta()
    //   this.setEmptyCart()
    //   this.unsetLastNameSearch()
    //   this.unsetDataRoom()
    //   this.resetToInitialState()
    //   this.setProducts()
    //   this.setSelectedCategory(null)      
    //   // this.$router.push({ name: 'auth-login' })
    //   localStorage.removeItem('vuex')
    //   window.location.reload(true)
    //   // window.location = window.location.href+'?eraseCache=true'
    // }
  },
}
</script>
<style scoped>
.canalventa-titulo{
  inline-size: 11rem;
  overflow: hidden;
  overflow-wrap: break-word;
}

.canalventa-titulo:hover{
  direction: rtl
}
.w-user-custom{
  width: 20rem;
}
</style>